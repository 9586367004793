import { authenticationService } from './authentication.service';
//Socket IO
import { io } from "socket.io-client";
import { socketIOURL } from '../_components/store_api';
const currentUser = authenticationService.currentUserValue != null ? true : false;
console.log(currentUser + window.location.pathname);

export const socket_io = io(socketIOURL, {
  // path: '/atikan',
  auth: {
    token: authenticationService.currentUserValue != null ? authenticationService.currentUserValue.data.token : null
  },
  transports: ['websocket', 'polling'],
  secure: true,
});