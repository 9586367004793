// //const online                       = 'https://atikan.dev.elemen.my.id/manajemen';
// const httpUrl                        = 'https://api.atikan.tikomdik-disdikjabar.id/manajemen';
// //const httpLogin                    = 'https://atikan.dev.elemen.my.id/sso';
// const httpLogin                      = 'http://103.155.104.14:8000';
// const httpVerify                     = 'http://103.155.104.14:3000';
// const lokal                          = 'http://192.168.2.248:6855';

let SSO_URL = process.env.REACT_APP_URL_SSO;
let LOGIN_URL = process.env.REACT_APP_URL_LOGIN_GLOBAL;

let URL = process.env.REACT_APP_URL_GLOBAL;
// let URL = process.env.REACT_APP_URL_LOKAL;
// let URL            = 'https://api.atikan.tikomdik-disdikjabar.id/manajemen';
let URL_SOCKET = 'https://api-atikan.jabarprov.go.id'

//Login
export const apiLogin = SSO_URL + '/auth';
export const apiVerifyAuth = URL + '/verifyAuth';
export const getUser = URL + '/getUserLogin';

//NOTIF
export const socketIOURL = URL_SOCKET;
export const notifKurikulum = URL + '/notification?is_viewed=0&tag=laporan_wali';

//DASHBOARD
export const apiGetPDDash = URL + '/kurikulum/rekap/pd_dashboard?';

//GET ADMIN
export const getKonfigurasi = URL + '/kcd/admin/configurasi/e-mutasi';

//POST ADMIN
export const postBerkas = URL + '/kcd/admin/configurasi/e-mutasi/jenis_file';

//PUT ADMIN
export const putConfig = URL + '/kcd/admin/configurasi/e-mutasi/config';

//IMPORT FILE
export const apiGetInfoRombel = URL + '/kurikulum/import/rombel/get-info';
export const apiGetInfoPD = URL + '/kurikulum/import/peserta_didik/get-info';
export const apiGetInfoPTK = URL + '/kurikulum/import/ptk/get-info';
export const apiGetFileRombel = URL + '/kurikulum/import/rombel/get-excel-rombel';
export const apiGetFilePD = URL + '/kurikulum/import/peserta_didik/get-excel-pd';
export const apiGetFilePTK = URL + '/kurikulum/import/ptk/get-excel-ptk'
export const apiGetInfoPembelajaran = URL + '/kurikulum/import/pembelajaran/get-info';
export const apiGetFilePembelajaran = URL + '/kurikulum/import/pembelajaran/get-excel-pembelajaran';

//REF
export const apiGetAgama = URL + '/kurikulum/ref/agama'
export const apiGetRef = URL + '/kurikulum/ref'
export const apiGetPangkatGol = URL + '/kurikulum/ref/pangkat_golongan'
export const apiGetJenisPTK = URL + '/kurikulum/ref/jenis_ptk'
export const apiGetLembagaPengangkat = URL + '/kurikulum/ref/lembaga_pengangkat'
export const apiGetStatusKepegawaian = URL + '/kurikulum/ref/status_kepegawaian'
export const apiGetPekerjaan = URL + '/kurikulum/ref/pekerjaan'
export const apiGetPerkawinan = URL + '/kurikulum/ref/status_perkawinan'
export const apiGetKelurahan = URL + '/kurikulum/ref/kelurahan'
export const apiGetKebutuhanKhusus = URL + '/kurikulum/ref/kebutuhan_khusus'
export const apiGetSumberAir = URL + '/kurikulum/ref/sumber_air'
export const apiGetKecukupanAir = URL + '/kurikulum/ref/kecukupan_air'
export const apiGetTipeJamban = URL + '/kurikulum/ref/tipe_jamban'
export const apiGetStatKepemilikan = URL + '/kurikulum/ref/status_kepemilikan';
export const apiGetWaktu = URL + '/kurikulum/ref/waktu_hari';
export const apiGetSumberListrik = URL + '/kurikulum/ref/sumber_listrik';
export const apiGetSertifikasiISO = URL + '/kurikulum/ref/sertifikasi_iso';
export const apiGetAksesInternet = URL + '/kurikulum/ref/akses_internet';
export const apiGetPendidikan = URL + '/kurikulum/ref/pendidikan';
export const apiGetPenghasilan = URL + '/kurikulum/ref/penghasilan';
export const apiGetKelurahanSekolah = URL + '/kurikulum/ref/sekolah/kelurahan'
export const apiGetKonsentrasi = URL + '/kurikulum/ref/jurusan/pk';
export const apiGetRombelPK = URL + '/kurikulum/rombel/smk/pk';
export const apiGetPTKTerdaftar = URL + '/kurikulum/ref/list_ptk_terdaftar';
export const refMapel = URL + '/kurikulum/ref/mata_pelajaran';

//STRUKTUR KURIKULUM
export const dataStrukturKurikulum = URL + '/kurikulum/struktur?';
export const dataStrukturKurikulumSMA = URL + '/kurikulum/struktur/sma?';
export const dataKonsentrasi = URL + '/kurikulum/jurusan/pusat_keunggulan';
export const dataKurikulumPK = URL + '/kurikulum/struktur/smk/pk';
export const dataKurikulumKP = URL + '/kurikulum/struktur/sma/ksp';

//DATA POKOK
export const dataPD = URL + '/kurikulum/master/master_pd_all';
export const dataPDOrtu = URL + '/kurikulum/master/master_pd/ortu/'
export const dataSekolah = URL + '/kurikulum/master/master_sekolah';

//====================MASTER====================//
export const apiGetPesertaDidik = URL + '/kurikulum/master/master_pd/'

export const apiGetPtk = URL + '/kurikulum/master/master_ptk'

export const apiGetPrasarana = URL + '/kurikulum/master/master_ruangan/'
export const apiGetRuangan = URL + '/kurikulum/master/master_ruangan/'
export const apiGetDetailRuangan = URL + '/kurikulum/master/master_ruangan/detail_ruang/'

//GET_Data
//-----------MDSP
export const apiJurusan = URL + '/kurikulum/jurusan?tipe_jurusan=';
export const apiJurusanSp = URL + '/kurikulum/jurusan/list';

export const apiRombel = URL + '/kurikulum/rombel';
export const apiRombelList = URL + '/kurikulum/rombel?jurusan_id=';
export const apiRombelDetail = URL + '/kurikulum/rombel/anggota/';
export const apiGetBentukPendidikan = URL + '/kurikulum/ref/bentuk_pendidikan';
export const apiGetSekolah2 = URL + '/kurikulum/ref/smk_sederajat';
export const apiGetListJurusan = URL + '/kurikulum/ref/smk_sederajat/jurusan';
export const apiGetRadio = URL + '/kurikulum/rombel/list_kompetensi?jurusan_id=';

export const apiPostSk = URL + '/kurikulum/jurusan';
export const apiDeleteJurusan = URL + '/kurikulum/jurusan/';

export const apiGetSemester = URL + '/kurikulum/rombel/semester';
export const apiCheckEmpatThn = URL + '/kurikulum/rombel/statusEmpatTahun?jurusan_id=';

export const apiGetMapel = URL + '/kurikulum/pembelajaran';
export const apiGetMapelGuru = URL + '/kurikulum/pembelajaran/guru';
export const apiGetGuru = URL + '/kurikulum/pembelajaran/guru/list?mata_pelajaran_id=';
export const apiGetRombelGuru = URL + '/kurikulum/pembelajaran/guru/rombel?ptk_terdaftar_id=';
export const apiFilterBidKeahlian = URL + '/kurikulum/pembelajaran/guru/sort_bidang?jurusan_id=';
export const apiFilterProgKeahlian = URL + '/kurikulum/pembelajaran/guru/sort_program?jurusan_id=';
export const apiFilterKompKeahlian = URL + '/kurikulum/pembelajaran/guru/sort_keahlian?jurusan_id=';

export const apiGetPdNaik = URL + '/kurikulum/rombel/list/naik?jurusan_id='; //15052&tingkat_pendidikan_id=11

export const apiGetWaliKelas = URL + '/kurikulum/rombel/wali';
export const apiGetJenisRombel = URL + '/kurikulum/rombel/jenis_rombel';

export const apiGetSoal = URL + '/kurikulum/soal';
export const apiGetJawaban = URL + '/kurikulum/soal/jawaban?id_soal=';
export const apiGetMateri = URL + '/kurikulum/materi';

export const apiGetJadwal = URL + '/kurikulum/pembelajaran/jadwal/kurikulum/'
export const apiGetJadwalGuru = URL + '/kurikulum/pembelajaran/guru/matpel/'
export const apiGetJadwalMapel = URL + '/kurikulum/pembelajaran/guru/matpel_terdaftar/'

export const apiGetMapelKD = URL + '/kurikulum/pembelajaran/mata_pelajaran/active';
export const apiGetKD = URL + '/kurikulum/pembelajaran/ki_kd/';
export const apiGetPenugasan = URL + '/kurikulum/pembelajaran/guru/list/ditugaskan?mata_pelajaran_id=';
export const apiGetDetailPenugasan = URL + '/kurikulum/pembelajaran/guru/list/ditugaskan/detail?mata_pelajaran_id=';

//PPDB
export const apiGetBentukPend = URL + '/kurikulum/ppdb/bentuk_pendidikan';
export const apiGetSekolah = URL + '/kurikulum/ppdb/swasta/sekolah/';//:kode_wilayah/:bentuk_pendidikan_id';
export const apiGetKodeWilayah = URL + '/kurikulum/ppdb/';

export const apiGetKab = URL + '/kurikulum/ppdb/wilayah?id_level_wilayah=2';//:npsn'
export const apiGetKec = URL + '/kurikulum/ppdb/wilayah?id_level_wilayah=';//3&mst_kode_wilayah=026900'//:npsn'
export const apiGetSiswaBaru = URL + '/kurikulum/ppdb/siswa';
export const apiGetPDPindahan = URL + '/kurikulum/ppdb/siswa_pindah?selection='; // 1 = Sudah Ditempatkan di Rombel Target, 0 = Pending || PESERTA DIDIK PINDAHAN ||
export const apiGetDetailPindahan = URL + '/kurikulum/ppdb/siswa_pindah/detail'; // rand_pdm_uuid

export const apiGetSMPProv = URL + '/kurikulum/ppdb/pd/luar?npsn_target=';
export const apiGetPDUsia = URL + '/kurikulum/ppdb/diterima/by_umur';
export const apiPerbandingan = URL + '/kurikulum/ppdb/perbandingan/quota';
export const apiJenjang = URL + '/kurikulum/ppdb/pendaftaran/satuan?selection=0';
export const apiPemetaanPPDB = URL + '/kurikulum/ppdb/diterima/radius';

//MUTASI
export const getDaftarMutasi = URL + '/kurikulum/rombel/pd_mutasi';
export const getPermintaan = URL + '/kurikulum/rombel/pd_mutasi/pengajuan';
export const getFilePermohonan = URL + '/kurikulum/rombel/pd_mutasi/uploaded-file?';
export const getFileMutasiPermohonan = URL + '/kurikulum/mutasi/pertimbangan-mutasi?'

//LOG
export const logRombel = URL + '/kurikulum/log/rombel/group';
export const logPD = URL + '/kurikulum/log/pd';

//REKAP
export const apiGetRekapPD = URL + '/kurikulum/rekap/peserta_didik';
export const apiGetRekapStatus = URL + '/kurikulum/rekap/peserta_didik_status';

//PENILAIAN
export const apiGetLogAktifitas = URL + '/kurikulum/materi/ranking/ptk';
export const apiDetailLogAktifitas = URL + '/kurikulum/materi/ranking/ptk/detail?ptk_id=';

//WALI KELAS
export const apiGetLaporanPD = URL + '/kurikulum/laporan/walikelas';

//POST
export const apiPostJurusan = URL + '/kurikulum/jurusan';
export const apiPostRombel = URL + '/kurikulum/rombel';
export const apiPostRombelPK = URL + '/kurikulum/rombel/smk/pk';
export const apiPostMapelGuru = URL + '/kurikulum/pembelajaran';
export const apiPostWaliKelas = URL + '/kurikulum/rombel/wali';
export const apiPostPPDB = URL + '/kurikulum/ppdb/with-files';
export const apiPostSiswaBaru = URL + '/kurikulum/ppdb/rombel';
export const apiPostJadwal = URL + '/kurikulum/pembelajaran/jadwal_kurikulum';
export const apiPostMutasi = URL + '/kurikulum/rombel/pd_mutasi';
export const apiPostPDPindahan = URL + '/kurikulum/ppdb/siswa_pindah';
export const apiPostRombel10 = URL + '/kurikulum/rombel/kelas_10';
export const apiPostPK = URL + '/kurikulum/jurusan/smk/pk';
export const apiPostSinkronPK = URL + '/kurikulum/rombel/smk/pk';
export const apiImportExcel = URL + '/kurikulum/import/rombel/convert-xlsx';
export const apiImportPD = URL + '/kurikulum/import/peserta_didik/convert-xlsx';
export const apiImportPTK = URL + '/kurikulum/import/ptk/convert-xlsx';
export const apiImportPembelajaran = URL + '/kurikulum/import/pembelajaran/convert-xlsx';
export const pushRombel = URL + '/kurikulum/log/rombel/group/push';
export const pushPD = URL + '/kurikulum/log/pd/group/push';
export const postMutasiKCD = URL + '/kurikulum/mutasi/input/rombel';
export const postTerimaMutasi = URL + '/kcd/permintaan/pd_mutasi'
export const kcdTerima = URL + '/kcd/permintaan/v2/pd_mutasi'
export const postFilePersetujuan = URL + '/kurikulum/mutasi/berkas/keterangan';
export const mutasiKCD = URL + '/kurikulum/mutasi/konfirmasi/kcd';

//PATCH
export const apiPatchJurusan = URL + '/kurikulum/jurusan/';
export const apiPatchPd = URL + '/kurikulum/rombel/anggota';
export const apiPatchPdRombel = URL + '/kurikulum/rombel/anggota';
export const apiPatchPdLulus = URL + '/kurikulum/rombel/anggota/lulus';
export const apiPatchSoal = URL + '/kurikulum/soal';
export const apiPatchJawaban = URL + '/kurikulum/soal/jawaban';
export const apiPatchMateri = URL + '/kurikulum/materi';
export const apiPatchMutasi = URL + '/kcd/permintaan/pd_mutasi';
export const patchMutasiKurikulum = URL + '/kurikulum/mutasi/pd_mutasi';
export const apiPatchRekap = URL + '/kurikulum/rekap/pd_rekap_patch';
export const apiPatchPassword = 'https://api.atikan.tikomdik-disdikjabar.id/sso/auth/password?password=';
export const apiPatchImportRombel = URL + '/kurikulum/import/rombel/konfirmasi-rombel';
export const apiPatchPD = URL + '/kurikulum/import/peserta_didik/konfirmasi-pd';
export const apiPatchPTK = URL + '/kurikulum/import/ptk/sync-ptk';
export const apiPatchPembelajaran = URL + '/kurikulum/import/pembelajaran/sync-pembelajaran';

//PUT
export const apiPutPGuru = URL + '/kurikulum/pembelajaran/guru';
export const apiPutPesertaDidik = URL + '/kurikulum/master/master_pd';
export const apiPutGuru = URL + '/kurikulum/master/master_ptk';
export const apiPutJadwal = URL + '/kurikulum/pembelajaran/jadwal_kurikulum';
export const apiPutLaporan = URL + '/kurikulum/laporan/walikelas/status';
export const apiPutEmail = URL + '/kurikulum/master/master_sekolah/email';
export const apiPutSekolah = URL + '/kurikulum/master/master_sekolah';
export const apiPutSanitasi = URL + '/kurikulum/master/master_sekolah/sanitasi';
export const apiPutPeriodik = URL + '/kurikulum/master/master_sekolah/periodik';
export const apiPutYayasan = URL + '/kurikulum/master/master_sekolah/yayasan';
export const apiPutNoID = URL + '/kurikulum/master/master_pd/nomor_induk';
export const apiPutRuangan = URL + '/kurikulum/master/master_ruangan';
export const apiPutImportRombel = URL + '/kurikulum/import/rombel/update-rombel';
export const apiPutImportPD = URL + '/kurikulum/import/peserta_didik/update-pd';
export const apiPutImportPTK = URL + '/kurikulum/import/ptk/update-ptk';
export const apiPutImportPembelajaran = URL + '/kurikulum/import/pembelajaran/update-pembelajaran';

//DELETE
export const apiDeleteJadwal = URL + '/kurikulum/pembelajaran/jadwal_kurikulum/';
export const apiDeleteAssign = URL + '/kurikulum/pembelajaran/guru/list/ditugaskan';
export const apiDeletePPDB = URL + '/kurikulum/ppdb/siswa_baru';
export const apiDeletePK = URL + '/kurikulum/jurusan/smk/pk';
export const apiDeleteImportRombel = URL + '/kurikulum/import/rombel/delete-rombel';
export const apiDeleteImportPD = URL + '/kurikulum/import/peserta_didik/delete-pd';
export const apiDeleteImportPTK = URL + '/kurikulum/import/ptk/delete-ptk';
export const apiDeleteImportPembelajaran = URL + '/kurikulum/import/pembelajaran/delete-pembelajaran';
export const apiResetImportRombel = URL + '/kurikulum/import/rombel/reset-rombel';
export const apiResetImportPD = URL + '/kurikulum/import/peserta_didik/reset-pd';
export const apiResetImportPTK = URL + '/kurikulum/import/ptk/reset-ptk';
export const apiResetImportPembelajaran = URL + '/kurikulum/import/pembelajaran/reset-pembelajaran';

//==================================================================================================================================
//==================================================================================================================================
//================================================== CABANG DINAS ==================================================================
//==================================================================================================================================
//==================================================================================================================================

export const kcdFileMutasi = URL + '/kcd/mutasi/pertimbangan-mutasi?';
export const dataLive = URL + '/kcd/live/penelusuran_rombel';
export const dataLivePD = URL + '/kcd/live/penelusuran_pd/data_pd'; //?semester_id=20211
export const pelaporanSekolah = URL + '/kcd/log/rombel';
export const pelaporanPD = URL + '/kcd/log/pd';
export const apiGetAkun = URL + '/kcd/rekap/akun_sekolah_sementara';
export const apiGetEmail = URL + '/kcd/penelusuran/list_sekolah/null_email';
export const apiGetWilayah = URL + '/kcd/penelusuran/data_sekolah?';
export const apiGetWilayahSekolah = URL + '/kcd/penelusuran/data_sekolah/sekolah?';
export const apiGetDetailSekolah = URL + '/kcd/penelusuran/data_sekolah/sekolah/detail?';
export const apiGetDetailKPA = URL + '/kcd/penelusuran/data_sekolah/sekolah/detail/kpa?';
export const apiGetPDWilayah = URL + '/kcd/penelusuran_pd/data_pd?';
export const apiGetPDSekolah = URL + '/kcd/penelusuran_pd/data_pd/sekolah?';
// export const apiGetPDSekolahJurusan  = URL + '/kcd/penelusuran_pd/data_pd/sekolah/bentuk?';
export const apiGetPDSekolahTingkat = URL + '/kcd/penelusuran_pd/data_pd/sekolah/bentuk/detail?';
export const apiGetGTKWilayah = URL + '/kcd/penelusuran_gtk/data_gtk?';
export const apiGetGTKSekolah = URL + '/kcd/penelusuran_gtk/data_gtk/sekolah?';
export const apiGetKurikulum = URL + '/kcd/penelusuran_kur';
export const apiGetSarpras = URL + '/kcd/penelusuran_sarpras';
export const apiGetRombel = URL + '/kcd/penelusuran_rombel'
export const satuanPD = URL + '/kcd/penelusuran_pd/data_pd/satuan/list_sekolah?';
export const satuanGuru = URL + '/kcd/penelusuran_gtk/data_gtk/satuan/list_sekolah?';
export const satuanSarpras = URL + '/kcd/penelusuran_sarpras/data_sarpras/satuan/list_sekolah?';
export const satuanSekolah = URL + '/kcd/penelusuran/data_sekolah/satuan/list_sekolah?';
export const satuanRombel = URL + '/kcd/penelusuran_rombel/data_rombel/satuan/list_sekolah?';

export const apiGetQRCode = URL + '/QRCode?id=';
export const apiGetChartRombel = URL + '/kcd/rekap/data/chart/rombel';
export const apiGetPDAktif = URL + '/kcd/rekap/siswa_aktif';
export const apiGetSekolahDash = URL + '/kcd/rekap/siswa_aktif';
export const apiGetPermintaan = URL + '/kcd/permintaan';
export const apiGetSemesterKCD = URL + '/kcd/rekap/semester';
export const apiGetRekapKCD = URL + '/kcd/rekap/status_rekap_sekolah';
export const apiGetListSekolah = URL + '/kcd/rekap/list_sekolah?semester_id=';
export const apiGetChartSiswa = URL + '/kcd/rekap/data/chart';
export const apiGetPercentRekap = URL + '/kcd/rekap/data/chart/isnot_rekap';
export const apiGetRekapJurusan = URL + '/kcd/rekap/jurusan';
export const apiGetJmlJurusan = URL + '/kcd/rekap/jurusan/detail';
export const apiGetJurusan = URL + '/kcd/rekap/rombel/per_jurusan?semester_id=';
export const apiGetJurusan1 = URL + '/kcd/rekap/peserta_didik/jurusan?';
export const apiGetJurusan2 = URL + '/kcd/rekap/peserta_didik/jurusan?tipe_jurusan=2&jurusan_induk=';
export const apiGetRombelX = URL + '/kcd/rekap/peserta_didik/jurusan?jurusan_id=';
export const getPelaporanBelum = URL + '/kcd/dashboard/xlsx?name_view=v_r_ppdb_swasta'
export const getPelaporanSudah = URL + '/kcd/dashboard/xlsx?name_view=v_r_ppdb_swasta_done'

//PPDB
export const apiGetPPDBWilayah = URL + '/kcd/penelusuran_ppdb/wilayah';
export const apiGetPPDBBentukPend = URL + '/kcd/penelusuran_ppdb/bentuk_pendidikan';
export const apiGetPPDBSekolah = URL + '/kcd/penelusuran_ppdb/sekolah/';
export const apiGetPPDBSiswa = URL + '/kcd/penelusuran_ppdb/sekolah/hasil_pendaftaran';
export const apiGetPPDBDetail = URL + '/kcd/penelusuran_ppdb/asal_sekolah/pd_pendaftaran?';
export const apiGetBerdasarkanUsia = URL + '/kcd/penelusuran_ppdb/diterima/by_umur?';
export const apiGetPerbandingan = URL + '/kcd/penelusuran_ppdb/perbandingan/quota?npsn=';
export const apiGetPemetaan = URL + '/kcd/penelusuran_ppdb/diterima/radius?'

export const apiGetMutasiSekolah = URL + '/kcd/mutasi/list_sekolah';

//POST
export const apiPostMutasiSekolah = URL + '/kcd/mutasi/sekolah';
export const pushRombelKCD = URL + '/kcd/log/rombel/group/push';
export const pushPDKCD = URL + '/kcd/log/pd/group/push';

//==================================================================================================================================
//==================================================================================================================================
//==================================================== WALI KELAS ==================================================================
//==================================================================================================================================
//==================================================================================================================================

//GET
export const walikelasGetRombel = URL + '/wali_kelas/peserta_didik'
export const walikelasGetPD = URL + '/wali_kelas/peserta_didik/anggota/';
export const walikelasGetLaporan = URL + '/wali_kelas/laporan';

//POST
export const walikelasPostPD = URL + '/wali_kelas/peserta_didik/anggota/invalid_pd';

//PUT
export const walikelasPutLaporan = URL + '/wali_kelas/laporan/cancel';

//==================================================================================================================================
//==================================================================================================================================
//====================================================== PROVINSI ==================================================================
//==================================================================================================================================
//==================================================================================================================================

//DASHBOARD
// export const provPermintaan          = URL + '/dinas/permintaan';
export const provPDAktif = URL + '/dinas/rekap/siswa_aktif';
export const provChartRombel = URL + '/dinas/rekap/data/chart/rombel';
export const provChartSiswa = URL + '/dinas/rekap/data/chart';
export const provPercentRekap = URL + '/dinas/rekap/data/chart/isnot_rekap';

//DASHBOARD CABANG DINAS
export const getChartKCD = URL + '/kcd/dashboard';

//DASHBOARD PROVINSI
export const getChartProv = URL + '/dinas/dashboard';

//DASHBOARD DAERAH
export const getChartDaerah = URL + '/daerah/dashboard';

//PENULUSURAN
export const provRef = URL + '/dinas/ref/';
export const provDataSekolah = URL + '/dinas/penelusuran/data_sekolah';
export const provKurikulum = URL + '/dinas/penelusuran_kur';
export const provRombel = URL + '/dinas/penelusuran_rombel';
export const provPD = URL + '/dinas/penelusuran_pd/data_pd?';
export const provPDSekolah = URL + '/dinas/penelusuran_pd/data_pd/sekolah?';
export const proPDSekolahTingkat = URL + '/dinas/penelusuran_pd/data_pd/sekolah/bentuk/detail?';
export const provGTK = URL + '/dinas/penelusuran_gtk/data_gtk?';
export const provGTKSekolah = URL + '/dinas/penelusuran_gtk/data_gtk/sekolah?';
export const provSarpras = URL + '/dinas/penelusuran_sarpras';
export const provSatuanSarpras = URL + '/dinas/penelusuran_sarpras/data_sarpras/satuan/list_sekolah?';
export const provSatuanSekolah = URL + '/dinas/penelusuran/data_sekolah/satuan/list_sekolah?';
export const provSatuanPD = URL + '/dinas/penelusuran_pd/data_pd/satuan/list_sekolah?';
export const provSatuanRombel = URL + '/dinas/penelusuran_rombel/data_rombel/satuan/list_sekolah?';
export const provSatuanGTK = URL + '/dinas/penelusuran_gtk/data_gtk/satuan/list_sekolah?';

//PPDB
export const provPPDBWilayah = URL + '/dinas/penelusuran_ppdb/wilayah?';
export const provBentukPen = URL + '/dinas/penelusuran_ppdb/bentuk_pendidikan';
export const provPPDBSekolah = URL + '/dinas/penelusuran_ppdb/sekolah/';
export const provPPDBSiswa = URL + '/dinas/penelusuran_ppdb/sekolah/hasil_pendaftaran?';
export const provPPDBDetail = URL + '/dinas/penelusuran_ppdb/asal_sekolah/pd_pendaftaran?';
export const provBerdasarkanUsia = URL + '/dinas/penelusuran_ppdb/diterima/by_umur?';
export const provPerbandingan = URL + '/dinas/penelusuran_ppdb/perbandingan/quota?';
export const provPemetaan = URL + '/dinas/penelusuran_ppdb/diterima/radius?'

// LEVEL DAERAH
// [Penelusuran]
// - Sekolah
export const daerahSekolahKec = URL + '/daerah/penelusuran/data_sekolah?';
export const daerahSekolahSP = URL + '/daerah/penelusuran/data_sekolah/sekolah?';
export const daerahDetailSekolah = URL + '/daerah/penelusuran/data_sekolah/sekolah/detail?'; //semester_id=20202&sekolah_id=f48f26c7-9400-4384-af23-2224b19f069b&kode_wilayah=026810&bentuk_pendidikan_id=1&mapping_school=5,6
export const daerahRombelKec = URL + '/daerah/penelusuran_rombel?'; //kode_wilayah=026700&semester_id=20202&date=2021-06-01
export const daerahRombelSekolah = URL + '/daerah/penelusuran_rombel/sekolah?'; //kode_wilayah=026018&semester_id=20202&date=2021-06-01
export const daerahPDKec = URL + '/daerah/penelusuran_pd/data_pd?'; //semester_id=20212&kode_wilayah=026000&date=2022-03-01
export const daerahPDSekolah = URL + '/daerah/penelusuran_pd/data_pd/satuan/list_sekolah?' //semester_id=20212&kode_wilayah=026808&date=2022-03-01&bentuk_pendidikan=1

//PPDB
export const getRefSLB = URL + '/kurikulum/rekap/slb/dashboard';
export const getPPDB = URL + '/kurikulum/ppdb/swasta/siswa/';//:npsn'
export const getSeleksi = URL + '/kurikulum/ppdb/slb';
export const postPPDB = URL + '/kurikulum/ppdb';
export const postSeleksi = URL + '/kurikulum/ppdb/slb';
export const getRekapSLB = URL + '/dinas/dashboard/slb';
export const getFormulir = URL + '/file-berkas/jenis-file?jenis_file_id=';
export const postFormulir = URL + '/kurikulum/ppdb/swasta/berkas/cpd';
export const postFormulir2 = URL + '/file-berkas';
export const getUploaded = URL + '/kurikulum/ppdb/swasta/uploaded-file?';//:peserta_didik_id'
export const postPD = URL + '/kurikulum/ppdb/swasta/add-pd';
export const deletePPDB = URL + '/kurikulum/ppdb/swasta/siswa_baru';
export const getRekapCD = URL + '/kcd/dashboard/pelaporan/rekap';
export const getFormasiRombel = URL + '/kurikulum/rombel?semester_id='; //&jurusan_id=